import React, {useState, useEffect, useRef} from "react";
import SectionTitle from '../SectionTitles/SectionTitle';
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'
import Poster from '../../assets/images/about/poster.png'
import ModalVideo from "react-modal-video";
import {getTranslated} from "../../utils/languageUtil";

const HomeAbout = () => {
    const [isOpen, setOpen] = useState(false)
    const content = getTranslated('Home')['about'];

    return (
        <div className="video-section section section-padding">
            <div className="container">
                <SectionTitle
                    title={content['title']}
                />

                <div className="row">


                    <div className="col-xl-7 col-lg-6 col-12">
                                <div className="video-popup-area">
                                    <div className="skill-video">

                                        <img className="image" src={Poster} alt="video popup" />
                                        <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                        <ModalVideo playsinline={true} channel='youtube' autoplay isOpen={isOpen} videoId="zN3_7W_hoMw" onClose={() => setOpen(false)}/>

                                    </div>
                                </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <p>{content['content']}</p>

                            <Link className="btn btn-primary btn-hover-light-btn mt-xl-8 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/product"}>{content['product']}</Link>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HomeAbout;
