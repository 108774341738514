import store from "./store";
import header from '../data/Header/header.json';
import home from '../data/Home/home.json';
import footer from '../data/Footer/footerItem.json';
import product from '../data/product/product.json';
import contact from '../data/Contact/contact.json';
import products from '../data/Products/products.json';
import privacy from '../data/PrivacyPolicy/privacyPolicy.json';
import cookies from '../data/Cookies/cookies.json';
function getBrowserLanguage() {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }

    return null;
}
function getTranslated(key){
    const lang = store.getState().currentLanguage.language
    switch(key){
        case "Header":
            return header[lang];
        case "Footer":
            return footer[lang];
        case "Home":
            return home[lang];
        case "Product":
            return product[lang];
        case "Contact":
            return contact[lang];
        case "Products":
            return products[lang];
        case "Privacy":
            return privacy[lang];
        case "Cookies":
            return cookies[lang];

    }
    return {};
}


export  {getBrowserLanguage, getTranslated};