import React, {useState} from 'react';
import PropTypes from "prop-types"
import {NavLink} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import store from "../../utils/store";
const LanguageToggle = () => {

    const language = mapStateToProps(store.getState()).language;
    const setLang = (e)=>{
        e.preventDefault();
        let setLanguage = e.target.innerHTML;
        store.dispatch({ type: "SET_LANGUAGE", setLanguage })
    }

    return (

        <div className="site-main-menu">
            <ul>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL+"/language"} style={{cursor:"default"}} onClick={(e)=>e.preventDefault()}><span className="menu-text">{language}</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to="/language/en" onClick={setLang}>EN</NavLink></li>
                        <li><NavLink to="/language/hr" onClick={setLang}>HR</NavLink></li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

LanguageToggle.propTypes = {

}

// export default LanguageToggle;
const mapStateToProps = state => {
    return  {language: state.currentLanguage.language};
};
export default connect(mapStateToProps)(LanguageToggle);
