import SectionTitle from '../SectionTitles/SectionTitle';
import {Link} from "react-router-dom";
import {getTranslated} from "../../utils/languageUtil";
const AboutProducts = () => {
    const content = getTranslated('Home')['aboutProducts'];
    return (
        <div className="section section-padding">
            <div className="container">

                <SectionTitle
                    title={content['title']}
                />

                <div className="row">


                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="">
                            <p className="text-right">{content['paragraph1']}</p>
                            <p className="text-right"><strong>{content['paragraph2']}</strong></p>
                            <p className="text-right">{content['paragraph3']}</p>
                            {/*<Link className="btn btn-primary btn-hover-light-btn mt-xl-8 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/about"}>Product</Link>*/}
                        </div>
                    </div>


                    <div className="col-xl-5 col-lg-6 col-12 overflow-hidden" data-aos="fade-up">
                        <div className="row g-2">
                                <div className="col">
                                    <Link to="/product/2"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_opentext_documentum.png"} alt="" /></Link>
                                    <Link to="/product/11"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_alfresco.png"} alt="" /></Link>
                                    <Link to="/product/4"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_generis_cara.png"} alt="" /></Link>
                                    <Link to="/product/3"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_opentext_content_suite.png"} alt="" /></Link>
                                </div>
                                <div className="col">
                                    <Link to="/product/12"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_file_share.png"} alt="" /></Link>
                                    <Link to="/product/8"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_dxc_firstdoc.png"} alt="" /></Link>
                                    <Link to="/product/5"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_sparta_trackwise_digital.png"} alt="" /></Link>
                                </div>
                                <div className="col">
                                    <Link to="/product/7"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_target_platform_microsoft_sharepoint.png"} alt="" /></Link>
                                    <Link to="/product/6"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_opentext_infoarchive.png"} alt="" /></Link>
                                    <Link to="/product/13"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_box.png"} alt="" /></Link>
                                </div>
                                <div className="col">
                                    <Link to="/product/10"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_microsoft_sharepoint.png"} alt="" /></Link>
                                    <Link to="/product/1"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_target_platform_opentext_documentum_for_life_sciences.png"} alt="" /></Link>
                                    <Link to="/product/9"><img className="my-1" src={process.env.PUBLIC_URL + "images/products/logo_square_hyland_onbase.png"} alt="" /></Link>
                                </div>
                        </div>


                    </div>


                </div>

            </div>
        </div>
    )
}

export default AboutProducts;
