import {Provider} from "react-redux";
import configureStore from "./utils/store";
import App from "./App";
const AppWrapper = () => {
    const store = configureStore;

    return (
        <Provider store={store}>
            <App />
        </Provider>

    )
}

export default AppWrapper;