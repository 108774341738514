import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";
import {getTranslated} from "../../utils/languageUtil";

const CookieConsentWrapper = ({update}) => {
    const content = getTranslated('Cookies');
    return (
        <CookieConsent
            style={{
            "alignItems": "center",
            "background": "white none repeat scroll 0% 0%",
            "borderTop": "2px solid #c6b889",
            "color": "white",
            "display": "flex",
            "flexWrap": "wrap",
            "justifyContent": "center",
            "left": "0px",
            "position": "fixed",
            "width": "100%",
            "zIndex": "99999",
            "bottom": "0px",
            "height": document.documentElement.clientWidth > 450 ? "15%" : "auto"
        }}

            disableStyles={true}
            buttonStyle={{
                "border": "0px none",
                "boxShadow": "none",
                "cursor": "pointer",
                "flex": "0 0 auto",
                "padding": "5px 10px",
                "margin": "15px"
        }}
            contentStyle={{
                "flex": "1 0 300px",
                "margin": "15px"
            }}
            location={"bottom"}
            buttonClasses="btn btn-primary  btn-hover-light-btn"
            containerClasses="text-center "
            buttonText={content['button']}
            onAccept={update}

        >
            <span className="text-dark">{content['text']+" "}</span>
        </CookieConsent>
    )
}

CookieConsentWrapper.propTypes = {
    update: PropTypes.func
};


export default CookieConsentWrapper;
