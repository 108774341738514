import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import {getTranslated} from "../../utils/languageUtil";

const PrivacyModal = ({show,setShow}) => {
    const content = getTranslated('Privacy');
    return (
        <Modal show={show} onHide={()=>setShow(!show)} centered scrollable size="lg" dialogClassName="pt-18" contentClassName="p-3">
            <Modal.Header closeButton>
                <Modal.Title>{content['title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{__html: content['text']}}>

            </Modal.Body>
        </Modal>
    )
}

PrivacyModal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func
};

export default PrivacyModal;