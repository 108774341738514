import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import {Link} from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import {getTranslated} from "../../utils/languageUtil";

const ProductOne = () => {
    const content = getTranslated('Product')['product'];
    return (
        <div className="section section-padding-bottom-200" style={{paddingTop:'125px'}}>
            <div className="container">

                <SectionTitle
                    title={content['title']}
                />

                <div className="row">


                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image-no-shadow">
                                        <img src={process.env.PUBLIC_URL + "images/products/heroshot_front_top_shadow.png"} alt="" />
                            </div>



                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">

                            <p>{content['p1']}</p>
                            <p><i className="fas fa-check"/> {content['p2']}</p>
                            <p><i className="fas fa-check"/> {content['p3']}</p>
                            <p><i className="fas fa-check"/> {content['p4']}</p>
                            <p><i className="fas fa-check"/> {content['p5']}</p>
                            <p><i className="fas fa-check"/> {content['p6']}</p>
                            <p><i className="fas fa-check"/> {content['p7']}</p>
                            <p><i className="fas fa-check"/> {content['p8']}</p>
                            <p><i className="fas fa-check"/> {content['p9']}</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ProductOne;
