import React from 'react';
import { useForm } from "react-hook-form";
import {getTranslated} from "../../utils/languageUtil";

const ContactFrom = () => {
    const { register, errors, handleSubmit } = useForm({
        mode: "onBlur"
    });
    const content = getTranslated('Contact')['contactContainer']['contactForm'];
    const handleFormSubmission = (e) => {
        let formData = new FormData();
        for (const key in e ) {
            formData.append(key, e[key]);
        }
        fetch("https://getform.io/f/378104e4-b9fb-493e-acbc-71d2d2239718",{method:"POST", body:formData})
            .then(res => {
                document.forms[0].style.display = "none"
                document.getElementById("form-message").innerText = content["submitted"];
            });
    }

    return (
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
            <form onSubmit={handleSubmit(handleFormSubmission)}>
                <div className="row mb-n6">
                    <div className="col-md-6 col-12 mb-6">
                        <input type="text" placeholder={content['name']} name="name" ref={register({ required: content['nameRequired'] })} />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                        <input type="email" placeholder="Email *" name="email" ref={register({
                            required: content['emailRequired'],
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: content['emailInvalid']
                            }
                        })} />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <div className="col-md-12 col-12 mb-6">
                        <input type="text" placeholder={content['subject']} name="subject" ref={register({ required: content['subjectRequired'] })} />
                        {errors.subject && <p>{errors.subject.message}</p>}
                    </div>
                    <div className="col-12 mb-6">
                        <textarea name="message" placeholder={content['message']} ref={register({ required: content['messageRequired'] })}></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                    <div className="col-12 text-center mb-6">
                        <button type="submit" className="btn btn-primary btn-hover-secondary">{content['btnSubmit']}</button>
                    </div>
                </div>
            </form>
            <p className="form-message text-center" id="form-message"></p>
        </div>
    )
}

export default ContactFrom;
