import {Fragment, useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import HomeOne from './pages/HomeOne';
import HomeTwo from './pages/HomeTwo';
import HomeThree from './pages/HomeThree';
import About from './pages/About';
import Service from './pages/Service';
import Work from './pages/Work';
import WorkDetails from './pages/WorkDetails';
import BlogGrid from './pages/BlogGrid';
import BlogClassic from './pages/BlogClassic';
import BlogDetails from './pages/BlogDetails';
import BlogCategories from './pages/BlogCategories';
import BlogTag from './pages/BlogTag';
import Contact from './pages/Contact';
import Product from './pages/Product';
import CacheBuster from 'react-cache-buster';
import CookieConsentWrapper from "./components/Cookies/CookieConsentWrapper";
import {getCookieConsentValue} from "react-cookie-consent";
import {version} from '../package.json';
import React from "react";
// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';
import {connect} from "react-redux";



function App() {
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: 'ease',
        });
        AOS.refresh();
    }, [])

    const isProduction = process.env.NODE_ENV === 'production';
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    return (
        <Fragment>
            {getCookieConsentValue()===undefined && <div style={{backgroundColor: "black", opacity: 0.4, width:"100%", height:"100%", zIndex:99999, position: "fixed"}}></div>}
            <CacheBuster
                currentVersion={version}
                isEnabled={isProduction} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
            >

                    <Router>
                        <NavScrollTop>
                            <Switch>
                                <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeOne}/>
                                {/*<Route path={`${process.env.PUBLIC_URL + "/home-one"}`} exact component={HomeOne}/>*/}
                                <Route path={`${process.env.PUBLIC_URL + "/product"}`} exact component={Product}/>
                                {/*<Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/home-three"}`} component={HomeThree}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/about"}`} component ={About} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/service"}`} component ={Service} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/work"}`} component ={Work} />*/}
                                <Route path={`${process.env.PUBLIC_URL + "/product/:id"}`} component={WorkDetails}/>
                                {/*<Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component ={BlogGrid} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component ={BlogClassic} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} component ={BlogTag} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`}component ={BlogCategories} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}component ={BlogDetails} />*/}
                                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact}/>
                                {/* <Route component ={NotFound} /> */}
                            </Switch>
                        </NavScrollTop>
                    </Router>

            </CacheBuster>

            <CookieConsentWrapper update={forceUpdate}/>
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {language: state.currentLanguage.language};
};
export default connect(mapStateToProps)(App);

