import {getBrowserLanguage} from "./languageUtil";

function getDefaultLanguageForSite(){
    let savedLang = localStorage.getItem('lang');
    if (savedLang && (savedLang==='HR' || savedLang==='EN')){
        return savedLang;
    }
    const browserLang = getBrowserLanguage();
    if (browserLang.toLowerCase() === 'hr'){
        return 'HR';
    }
    return 'EN';
}
export default function  languageReducer(state = { language: getDefaultLanguageForSite() }, action) {

    switch (action.type) {
        case "SET_LANGUAGE":
            localStorage.setItem('lang',action.setLanguage);
            return { ...state, language: action.setLanguage };
        default:
            return state;
    }
}
