import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ServiceIconBoxTwo from '../container/Product/ServiceIconBoxTwo';
import ServiceSkill from '../container/service/ServiceSkill';
import AboutSix from '../container/About/AboutSix';
import FunfactTwo from '../container/Funfact/FunfactTwo';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ProductOne from "../container/Product/ProductOne";
import Funfact from "../container/Funfact/Funfact";
import ContactInformationTwo from "../container/ContactInformation/ContactInformationTwo";
import ProductIconBox from "../container/Product/ProductIconBox";


const Product = () => {
    return (
        <React.Fragment>
            <SEO title="Migration Center || Product" />
            <Header />
            {/*<Breadcrumb */}
            {/*    image="images/bg/breadcrumb-bg-three.jpg"*/}
            {/*    title="We work with bold brands that we believe in"*/}
            {/*    content="Home"*/}
            {/*    contentTwo="Services"*/}
            {/*/>*/}
            <ProductOne />
            <ServiceIconBoxTwo />
            <FunfactTwo />
            <ProductIconBox />
            <ContactInformationThree />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Product;



