import React, {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";
import {getTranslated} from "../../utils/languageUtil";

const HomeKeyFigures = () => {
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const content = getTranslated('Home')['keyFigures'];
    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };

    return (
        <div className="section section-padding">
            <div className="container">

                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                title={content['title']}
                            />

                            <div className="row mb-n6">
                                <div className="col-md-6 col-sm-6 col-12 mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{top: 10}}
                                                delayedCall
                                            >
                                                <CountUp end={didViewCountUp ? 220 : 0}/>
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">{content['migrationPaths']}</h6>
                                    </div>
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{top: 10}}
                                                delayedCall
                                            >
                                                <CountUp end={didViewCountUp ? 1500 : 0}/>
                                            </VisibilitySensor>+
                                        </div>
                                        <h6 className="text">{content['projects']}</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 mb-6">
                                    <div className="about-funfact">
                                        <div className="number">
                                            <VisibilitySensor
                                                onChange={onVisibilityChange}
                                                offset={{top: 10}}
                                                delayedCall
                                            >
                                                <CountUp end={didViewCountUp ? 100 : 0}/>
                                            </VisibilitySensor>%
                                        </div>
                                        <h6 className="text">{content['box']}</h6>
                                    </div>
                                    <div className="col-md-5 col-sm-6 col-12 mb-6">
                                        <div className="about-funfact">
                                            <div className="number">
                                                <VisibilitySensor
                                                    onChange={onVisibilityChange}
                                                    offset={{top: 10}}
                                                    delayedCall
                                                >
                                                    <CountUp end={didViewCountUp ? 16 : 0}/>
                                                </VisibilitySensor>+
                                            </div>
                                            <h6 className="text">{content['experience']}</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area mt-5">
                            <p>{content['paragraph1']}</p>
                            <p><strong>{content['paragraph2']}</strong></p>
                        </div>
                        <Link className="btn btn-primary btn-hover-light-btn mt-xl-8 mt-lg-8 mt-md-6 mt-4"
                              to={process.env.PUBLIC_URL + "/contact"}>{content['trial']}</Link>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HomeKeyFigures;
