import {Fragment, useState} from "react";
import Logo from '../../components/logo/Logo';
import NavBar from '../../components/NavBar/NavBar';
import HeaderSearch from '../../components/HeaderSearch/HeaderSearch';
import Btn from '../../components/Btn/Btn';
import MobileMenu from "../../components/NavBar/MobileMenu"
import MainSearch from "../../components/NavBar/MainSearch"
import {Link, NavLink} from "react-router-dom";
import {getBrowserLanguage, getTranslated} from '../../utils/languageUtil.js'
import WorkData from "../../data/work/workDetails.json";
import {Dropdown, DropdownButton} from "react-bootstrap";
import LanguageToggle from "../../components/NavBar/LanguageToggle";

const Header = () => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const content = getTranslated('Header');
    // const [searchbarShow, setSearchbarShow] = useState(false);
    // const onSearchHandler = () => {
    //     setSearchbarShow(prev => !prev);
    // }


    return (
        <Fragment>
            <div className={`header-section header-transparent sticky-header section is-sticky`}>
                <div className="header-inner">
                    <div className="container position-relative">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-xl-2 col-auto order-0">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                />
                            </div>
                            <div className="col-auto col-xl d-flex align-items-center justify-content-end order-2 order-xl-1 ">
                                <div className="menu-column-area d-none d-xl-block position-static ">
                                    <NavBar data={content}/>
                                </div>
                                {/*<div className="header-search-area ml-xl-7 ml-0">*/}

                                {/*    <HeaderSearch onClick={onSearchHandler}/>*/}
                                {/*</div>*/}

                                <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                                    <button type="button" className="toggle" onClick={onCanvasHandler}>
                                        <i className="icon-top"></i>
                                        <i className="icon-middle"></i>
                                        <i className="icon-bottom"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-xl-2 col d-none d-sm-flex justify-content-end order-1 order-xl-2">
                                <Link to={process.env.PUBLIC_URL + "/contact"}>
                                    <button className="btn btn-primary btn-hover-light-btn" >{content['contactUs']}</button>
                                </Link>

                            </div>

                            <div className="col-xl-1 col d-none d-sm-flex order-1 order-xl-2 ">
                                <LanguageToggle />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileMenu show={ofcanvasShow} data={content} onClose={onCanvasHandler}/>
            {/*<MainSearch show={searchbarShow} onClose={onSearchHandler}/>*/}
        </Fragment>
    )
}

export default Header;