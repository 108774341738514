import PropTypes from "prop-types";
import React from 'react';
import ReactVivus from 'react-vivus';
import {Link} from "react-router-dom";
import SectionTitle from "../SectionTitles/SectionTitle";
import CustomVivus from "./customVivus";


const IconBox = ({ data, classOption }) => {

    return (
        <div className={`icon-box text-center ${classOption}`}>
            {data.icon &&
            <div className="icon">
                <CustomVivus
                    id={`servicesvg-${data.id}`}
                    option={{
                        file: data.icon,
                        animTimingFunction: 'EASE',
                            type: 'oneByOne',
                            delay: 80
                    }}
                />
            </div>}
            <div className="content">
                <h3 className="title">{data.title}</h3>
                <div className="desc">
                    <p>{data.desc}</p>
                </div>
                {(data.links && data.links.map((single, key) => {
                    return (
                     <div key={key} className="text-left mt-3">
                        <p className="sub-title mb-0"><b>{single.pageLinkTitle}</b></p>
                        <a href={single.link} className="link">{single.pageLink}</a>
                    </div>
                    )
                }))}

            </div>
        </div>
    )
}

IconBox.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

IconBox.defaultProps = {
    classOption: "icon-box text-center",
};

export default IconBox;
