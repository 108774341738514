import React from 'react'
import {NavLink} from 'react-router-dom';
import WorkData from "../../data/work/workDetails.json";
import PropTypes from "prop-types";
import WorkDetailsContainer from "../../container/Work/WorkDetailsContainer";
import {getTranslated} from "../../utils/languageUtil";

const NavBar = ({data}) => {
    const content = getTranslated('Products');
    return (

        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink exact to={process.env.PUBLIC_URL + "/"}><span className="menu-text">{data['home']}</span></NavLink>
                    {/*<span className="menu-toggle"><i className="far fa-angle-down"></i></span>*/}
                    {/*<ul className="sub-menu">*/}
                    {/*    <li><NavLink to={process.env.PUBLIC_URL + "/home-one"}><span className="menu-text">Home One</span></NavLink></li>*/}
                    {/*    <li><NavLink to={process.env.PUBLIC_URL + "/home-two"}><span className="menu-text">Home Two</span></NavLink></li>*/}
                    {/*    <li><NavLink to={process.env.PUBLIC_URL + "/home-three"}><span className="menu-text">Home Three</span></NavLink></li>*/}
                    {/*</ul>*/}
                </li>
                <li>
                    <NavLink exact to={process.env.PUBLIC_URL + "/product"}><span className="menu-text">{data['products']}</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink exact to={process.env.PUBLIC_URL+"/products"} style={{cursor:"default"}} onClick={(e)=>e.preventDefault()}><span className="menu-text">{data['supportedPlatforms']}</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        {content.map((value, i) => {
                            return(
                                <li key={i}><NavLink exact  to={process.env.PUBLIC_URL + `/product/${value.id}`}><span className="menu-text" >{value.title}</span></NavLink></li>
                            )
                        })}
                    </ul>
                </li>

                {/*<li className="has-children">*/}
                {/*    <NavLink to={process.env.PUBLIC_URL + "/work"}><span className="menu-text">Work</span></NavLink>*/}
                {/*    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>*/}
                {/*    <ul className="sub-menu">*/}
                {/*        <li><NavLink to={process.env.PUBLIC_URL + "/work"}><span className="menu-text">Work</span></NavLink></li>*/}
                {/*        <li><NavLink to={process.env.PUBLIC_URL + `/work-details/1`}><span className="menu-text">Work Details</span></NavLink></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                {/*<li className="has-children">*/}
                {/*    <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog</span></NavLink>*/}
                {/*    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>*/}
                {/*    <ul className="sub-menu">*/}
                {/*        <li><NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog Grid</span></NavLink></li>*/}
                {/*        <li><NavLink to={process.env.PUBLIC_URL + "/blog-classic"}><span className="menu-text">Blog classic</span></NavLink></li>*/}
                {/*        <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>*/}
                {/*</li>*/}
            </ul>
        </nav>
    )

}
NavBar.propTypes = {
    data: PropTypes.object

}

export default NavBar
