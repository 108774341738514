import PropTypes from "prop-types";
import {useEffect, useRef} from "react";
import IconBoxData from '../../data/iconBox/icon-box.json';
import IconBox from '../../components/IconBox/IconBox.jsx';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import Parallax from 'parallax-js';
import ProductIconBox from "../../components/IconBox/ProductIconBox";

const ProductIconBoxTwo = ({ data,title2, classOption }) => {

    return (
        <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}>
            <div className="container">

                <SectionTitle
                    headingOption="fz-32"
                    title={title2}
                />

                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 icon-box-shape-animation">

                    {data && data.map((single, key) => {
                        return(
                            <div key={key} className="col mb-6" data-aos="fade-up" data-aos-delay="300">
                                <ProductIconBox classOption="box-border icon-box-pad-less" data={single} key={key} />
                            </div>
                        ); 
                    })}


                </div>

            </div>
        </div>
    )
}

ProductIconBoxTwo.propTypes = {
    classOption: PropTypes.string,
    data: PropTypes.array,
    title2: PropTypes.string
};
ProductIconBoxTwo.defaultProps = {
    classOption: "section section-padding-t90 section-padding-bottom",
    title2: "Platform-specific use cases"
};

export default ProductIconBoxTwo
