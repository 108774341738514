import React from 'react';
import {NavLink} from 'react-router-dom';
import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils";
import WorkData from "../../../data/work/workDetails.json";
import PropTypes from "prop-types";
import MobileMenu from "../MobileMenu";
import {getTranslated} from "../../../utils/languageUtil";
import LanguageToggle from "../LanguageToggle";
import store from "../../../utils/store";

const MobileNavMenu = ({data}) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle-nolink")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    const content = getTranslated('Products');
    const onClickNoGo = (e) => {
        e.preventDefault();
        onClickHandler(e);
    };
    const language = mapStateToProps(store.getState()).language;
    const setLang = (e) => {
        e.preventDefault();
        let setLanguage;
        if (e.target.innerHTML === 'EN' || e.target.innerHTML === 'HR') { //iz nekog razloga nekad ovo prolazi nekad ne ????
            setLanguage = e.target.innerHTML;
        } else {
            setLanguage = e.target.children[0].innerHTML;
        }
        store.dispatch({type: "SET_LANGUAGE", setLanguage})
    }
    return (
        <nav className="site-mobile-menu">
            <ul>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span
                        className="menu-text">{data['home']}</span></NavLink>
                    {/*<span className="menu-toggle" onClick={onClickHandler}><i className="icon far fa-angle-down"></i></span>*/}

                </li>
                {/*<li>*/}
                {/*    <NavLink to={process.env.PUBLIC_URL + "/product"}><span className="menu-text">Product</span></NavLink>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <NavLink to={process.env.PUBLIC_URL + "/service"}><span className="menu-text">Services</span></NavLink>*/}
                {/*</li>*/}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/product"}><span
                        className="menu-text">{data['products']}</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL} className="menu-toggle-nolink" onClick={onClickNoGo}><span
                        className="menu-text">{data['supportedPlatforms']}</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        {content.map((value, i) => {
                            return (
                                <li key={i}><NavLink to={process.env.PUBLIC_URL + `/product/${value.id}`}><span
                                    className="menu-text">{value.title}</span></NavLink></li>
                            )
                        })}

                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span
                        className="menu-text">{data['contactUs']}</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL} className="menu-toggle-nolink" onClick={onClickNoGo}><span
                        className="menu-text">{language}</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL} onClick={setLang}><span
                            className="menu-text">EN</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL} onClick={setLang}><span
                            className="menu-text">HR</span></NavLink></li>
                    </ul>
                </li>
            </ul>
        </nav>
    )
}
MobileNavMenu.propTypes = {
    data: PropTypes.object
}
const mapStateToProps = state => {
    return {language: state.currentLanguage.language};
};
export default MobileNavMenu;
